import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, NgModule, OnChanges, OnInit, forwardRef } from '@angular/core';
import {
	FormsModule,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { ComptaService } from '@app/compta/compta.service';
import { Convention } from '@app/convention/convention.model';
import { ConventionService } from '@app/convention/convention.service';
import { TcoType } from '@app/convention/type-convention.model';
import { LogoDisplayModule } from '@app/logo/logo-display';
import { PortailParam, PortailService } from '@app/portail/portail.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { getCurrentPivotedDate, removeTimePart } from '@helpers/utils';


@Component({
	selector: 'convention-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ConventionSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[disabled]="disabled || loading"
			[placeholder]="_placeholder"
			[readonly]="readonly"
			[showClear]="nullOption"
			[filter]="options.length > 5"
			filterBy="label"
			[filterPlaceholder]="filterPlaceholder"
			optionValue="con_id"
			[optionLabel]="showPartenariatName? 'label' : 'label_short'"
			[required]="required"
			appendTo="body"
		>
			<ng-template let-item pTemplate="item">
				<div class="selector-item flex align-items-center">
					<div class="selector-item-icon" *ngIf="showLogo && item.ctx_id">
						<logo-display [entity]="item" [listing]="true" size="sm" [placeholderIfNone]="true"></logo-display>
					</div>
					<div [ngClass]="{'pl-2': showLogo}">{{showPartenariatName? item.label : item.label_short }}</div>
				</div>
			</ng-template>
			<ng-template pTemplate="selectedItem">
				<ng-container *ngIf="_selected">
					<div class="flex align-items-center">
						<div class="selector-item-icon" *ngIf="showLogo && _selected">
							<logo-display [entity]="_selected" [listing]="true" size="sm" [placeholderIfNone]="true"></logo-display>
						</div>
						<div [ngClass]="{'pl-2': showLogo}" class="overflow-hidden white-space-nowrap text-overflow-ellipsis">{{showPartenariatName? _selected.label : _selected.label_short }}</div>
					</div>
				</ng-container>
			</ng-template>
		</p-dropdown>
	`
})
export class ConventionSelectorComponent extends ControlValueAccessorBase<number|null|undefined> implements OnInit, OnChanges {

	@Input() name: string;
	@Input('value') innerValue: number|null|undefined;
	@Input() disabled: boolean;
	@Input() readonly: boolean;
	@Input() portail: PortailParam;
	@Input() grc_id: number;
	@Input() ctx_id: number;
	@Input() drf_id: number; // demande de règlement
	@Input() etc_code: string; // état convention
	@Input() tco_type: TcoType; // type convention
	@Input() con_actif: boolean; // convention actives
	@Input() placeholder: string = 'Sélectionnez';
	@Input() placeholderEmpty: string = 'Aucune convention disponible';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() autoSelectCurrent: boolean;
	@Input() useAccesGroupementModifier: boolean;
	@Input() useAccesGroupeModifier: boolean;
	@Input() additionalParams: any = {};
	@Input() showPartenariatName: boolean;
	@Input() pivotDay: number;
	@Input() disableItemsFunction: Function;
	@Input() filterItemsFunction: Function;
	@Input() showLogo: boolean = true;
	@Input() required: boolean = false;

	// https://stackoverflow.com/questions/39754656/how-to-detect-if-angular-2-component-has-attribute
	@Input() public set annee(value: number) {
		this.hasAttrAnnee = true;
		this._annee = value;
	}

	hasAttrAnnee: boolean;
	_annee: number;
	_placeholder: string = ' ';
	_rawOptions: Convention[] = [];
	options: Convention[] = [];
	loading: boolean = false;

	_selected: any;


	constructor(
		public elementRef: ElementRef,
		private conventionService: ConventionService,
		private comptaService: ComptaService,
		private portailService: PortailService
	) {
		super();
	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (
			typeof changes.ctx_id != 'undefined' && changes.ctx_id.previousValue != changes.ctx_id.currentValue
			|| typeof changes.grc_id != 'undefined' && changes.grc_id.previousValue != changes.grc_id.currentValue
			|| typeof changes.annee != 'undefined' && changes.annee.previousValue != changes.annee.currentValue
			|| typeof changes.drf_id != 'undefined' && changes.drf_id.previousValue != changes.drf_id.currentValue
			|| typeof changes.portail != 'undefined' && changes.portail.previousValue != changes.portail.currentValue
		) {
			this.innerValue = undefined;
			this.load();
		}
	}

	override get value() {
		return this.innerValue;
	}

	override set value(value: number|null|undefined) {
		this.innerValue = value;
		this._selected = this.options.find((one: Convention) => {return one.con_id  == value});
		this.onChange(value);
		this.onTouched();
	}

	getEntity() {
		const found = this.options.find((one: Convention) => { return one.con_id == this.innerValue; });
		if (found) {
			return found;
		}
		return null;
	}

	getEntityName() {
		const found = this.options.find((one: Convention) => { return one.con_id == this.innerValue; });
		if (found) {
			return found.label;
		}
		return null;
	}

	load() {
		let params = {
			etc_code: this.etc_code,
			con_actif: this.con_actif,
			annee: this._annee
		};

		// ne pas charger si on n'a pas précisé d'année alors que l'attribut existe
		if (this.hasAttrAnnee && !params.annee) return;

		if (this.additionalParams) {
			params = Object.assign(params, this.additionalParams);
		}

		let ctx_id: number|null|undefined = this.ctx_id;
		let grc_id: number|null|undefined = this.grc_id;

		if (this.useAccesGroupeModifier) {
			let tmp = this.portailService.getAccesGroupeParams(grc_id, ctx_id, this.useAccesGroupementModifier);
			ctx_id = tmp.ctx_id;
			grc_id = tmp.grc_id;
		}

		if (this.drf_id) {
			this.loadConventionsOfDemandeReglement(params, grc_id, ctx_id);
		}
		else {
			this.loadRegular(params, grc_id, ctx_id);
		}
	}

	loadConventionsOfDemandeReglement(params: any, grc_id?: number|null, ctx_id?: number|null) {
		this.loading = true;
		this.comptaService.getConventionsOfDemandeReglement(params, this.drf_id, grc_id, ctx_id)
		.subscribe(
			response => {
				this.prepareOptions(response);
			}
		)
		.add(() => {this.loading = false;});
	}

	loadRegular(params: any, grc_id?: number|null, ctx_id?: number|null) {

		this.loading = true;
		this.conventionService.getConventions(params, ctx_id, grc_id, this.portail, this.tco_type)
		.subscribe(
			(response: any) => {
				this.prepareOptions(response);
			}
		)
		.add(() => {this.loading = false;});
	}

	prepareOptions(response: any = this._rawOptions) {
		let values: Convention[] = [];
		if (response.conventions_partenariats) {
			values = response.conventions_partenariats;
		}
		else if (Array.isArray(response)) {
			values = response;
		}

		this._rawOptions = [...values];

		if (typeof this.filterItemsFunction != 'undefined') {
			values = this.filterItemsFunction(values);
		}

		if (typeof this.disableItemsFunction != 'undefined') {
			values = this.disableItemsFunction(values);
		}

		this._placeholder = (values.length)? this.placeholder : this.placeholderEmpty;

		this.options = values;
		this.setInitialValue();
	}

	setInitialValue() {
		if (!this.options.length) {
			this.innerValue = null;
		}
		else {
			if (typeof this.innerValue != 'undefined') {
				if (this.options.findIndex(one => {return one.con_id == this.innerValue; }) < 0) {
					if (this.nullIfInvalid) {
						this.innerValue = null;
					}
				}
			}
			if (this.autoSelectCurrent && !this.innerValue) {
				let pivotedDate = getCurrentPivotedDate(new Date(), this.pivotDay);
				let index = this.options.findIndex((one: Convention) => {
					return removeTimePart(one.con_date_debut) <= pivotedDate && removeTimePart(one.con_date_fin) >= pivotedDate;
				});
				if (index >= 0) {
					this.innerValue = this.options[index].con_id;
				}
			}
		}

		this.value = this.innerValue;

		this._selected = this.options.find((one: Convention) => {return one.con_id  == this.value});

	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
		LogoDisplayModule,
	],
	exports: [ConventionSelectorComponent],
	declarations: [ConventionSelectorComponent]
})
export class ConventionSelectorModule { }
