import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Agence } from '@app/agence/agence.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { SocieteService } from '@app/societe/societe.service';
import { TypeAgence } from '@app/agence/type-agence.model';

import { clone, convertDateFieldsToDate } from '@helpers/utils';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AgenceService {

	typesAgence: TypeAgence[];

	constructor(
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private societeService: SocieteService
	) {
		this.typesAgence = listesGeneriquesService.getListe('types_agences');
	}

	public prepareForServer(obj: Agence) {
		let prepared = clone(obj);
		prepared.societe = this.societeService.prepareForSubmit(obj.societe);
		return prepared;
	}

	public prepareFromServer(data: any): Agence {
		let prepared = structuredClone(data);
		prepared.ctx_id = prepared.ctx_fil_id;
		convertDateFieldsToDate(prepared); // convertit aussi les dates de la société
		return prepared as Agence;
	}

	public get getTypesAgence() {
		return [...this.typesAgence];
	}

	public getAgences(params: any, ctx_id?: number) {
		let observable: Observable<any>;

		if (ctx_id != undefined) {
			observable = this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/agences_filiales`, prepareQueryParams(params));
		}
		else {
			observable = this.http.get<any>(`${environment.api_url}/agences_filiales`, prepareQueryParams(params));
		}

		return observable.pipe(map((response: any) => {
			return {
				total: response.total as number,
				agences_filiales: Array.from(response.agences_filiales).map(this.prepareFromServer)
			};
		}))
	}

	public get(age_id: any, ctx_id?: number) {
		if (ctx_id != undefined) {
			return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/agences_filiales/${age_id}`)
			.pipe(map(obj => this.prepareFromServer(obj)));
		}
		return this.http.get<any>(`${environment.api_url}/agences_filiales/${age_id}`)
		.pipe(map(obj => this.prepareFromServer(obj)));
	}

	public post(agence: Agence) {
		let prepared = this.prepareForServer(agence);
		return this.http.post<any>(`${environment.api_url}/contextes/${agence.ctx_fil_id}/agences_filiales`, prepared);
	}

	public put(agence: Agence) {
		let prepared = this.prepareForServer(agence);
		return this.http.put<any>(`${environment.api_url}/contextes/${prepared.ctx_fil_id}/agences_filiales/${prepared.age_id}`, prepared);
	}

	public delete(agence: Agence) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${agence.ctx_fil_id}/agences_filiales/${agence.age_id}`);
	}

	public exportList(params: any, ctx_id?: number) {
		let tmpParams = prepareQueryParamsForDownload(params);

		if (ctx_id != undefined) {
			return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/export_agences_filiales`, tmpParams);
		}
		return this.http.get<any>(`${environment.api_url}/agences_filiales/export_pour_groupement`, tmpParams);
	}

	public getUrlUploadAgencesFiliales(ctx_id?: number) {
		if (ctx_id != undefined) {
			return `${environment.api_url}/contextes/${ctx_id}/import_agences_filiales`;
		}
		return `${environment.api_url}/agences_filiales/import`;
	}

	public exportAgencesFiliales() {
		return this.http.get<any>(`${environment.api_url}/agences_filiales/export`, prepareQueryParams(null, true));
	}

}
